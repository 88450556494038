import { useRouter } from "next/navigation";
import styles from "./EventLineup.module.css";
import Image from "next/image";

const EventLineup = ({ lineup, artistsData }) => {
  const router = useRouter();
  const handleLineUp = (e, line) => {
    e.preventDefault();
    localStorage.setItem("lineup", JSON.stringify(line));
    router.push("/lineup");
    // Retrieving the JSON object from local storage
  };

  return (
    <div className={styles.lineup_wrap}>
      <h2>LINEUP</h2>
      <div className={styles.lineup}>
        {artistsData &&
          artistsData.length > 0 &&
          artistsData.map((people, index) => {
            console.log(people);
            return (
              <div
                className={styles.people}
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  router.push(`/artists/${people.artistID}`);
                }}
              >
                <div
                  style={{ backgroundImage: `url(${people.imageUrl})` }}
                  className={styles.lineup_img}
                ></div>
                <span>{people.name}</span>
              </div>
            );
          })}
        {lineup.map((people, index) => {
          console.log(people.image);
          return (
            <div
              className={styles.people}
              key={index}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                handleLineUp(e, people);
              }}
            >
              <div
                // style={{ backgroundImage: `url(${people.image})` }}
                className={styles.lineup_img}
              >
                <Image
                  src={people.image}
                  width={180}
                  height={180}
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                />
              </div>
              <span>{people.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EventLineup;
