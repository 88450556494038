"use client";

import { useState, useEffect } from "react";
import {
  formatDate,
  StartDateRange,
  EndDateRange,
} from "../utils/dateFormatter";

const useFormatDates = (startDate, endDate, timezoneMap) => {
  const [eventStartDate, setEventStartDate] = useState(null);
  const [startRange, setEventStartRange] = useState(null);
  const [endRange, setEventEndRange] = useState(null);
  // console.log("asddddddddddddddddddddddddddddddd", timezoneMap);
  useEffect(() => {
    if (startDate && endDate && timezoneMap) {
      setEventStartDate(formatDate(startDate, timezoneMap.offset));
      setEventStartRange(StartDateRange(startDate, timezoneMap.offset));
      setEventEndRange(EndDateRange(endDate, timezoneMap.offset));
    }
  }, [startDate, endDate, timezoneMap]);

  return [eventStartDate, startRange, endRange];
};

export default useFormatDates;
