"use client";
import styles from "./EventTitle.module.css";
import useFormatDates from "../../../hooks/useFormatDates";

const EventTitle = ({
  name,
  orgSnippet,
  startDate,
  endDate,
  timezoneMap,
  orgID,
}) => {
  const [eventStartDate, startRange, endRange] = useFormatDates(
    startDate,
    endDate,
    timezoneMap
  );
  return (
    <div className={styles.title_wrap}>
      <div className={styles.title_left}>
        {name && <h1>{name}</h1>}
        {orgSnippet.orgName && (
          <div className={styles.org_wrap}>
            <a
              style={{ textDecoration: "none", color: "white", display:"flex",alignItems:"center" }}
              href={`/organizations/${orgID}`}
            >
              <img
                src={
                  orgSnippet && orgSnippet.orgImage === ""
                    ? "/assets/images/logo.png"
                    : orgSnippet.orgImage
                }
                alt=""
              />
              &nbsp;
              <span>{orgSnippet.orgName}</span>
            </a>
          </div>
        )}
      </div>
      {eventStartDate && (
        <div className={styles.title_right}>
          <span>{eventStartDate}</span>
        </div>
      )}
    </div>
  );
};

export default EventTitle;
