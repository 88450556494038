import React from "react";
import styles from "./EventCategories.module.css";
import { FaBullseye } from "react-icons/fa";

const EventCategories = ({ musicTypes, details }) => {
  // return <div></div>;
  const detail = details !== undefined ? details : false;
  if (!Array.isArray(musicTypes)) {
    return <div>No music types provided.</div>;
  }
  return musicTypes?.length <= 0 ? (
    <div></div>
  ) : detail ? (
    <div className={styles.wrapper}>
      {musicTypes.map((music, index) => {
        return <div key={index}>{music}</div>;
      })}
    </div>
  ) : (
    <div className={styles.wrapper}>
      {musicTypes.map(
        (music, index) => index < 3 && <div key={index}>{music}</div>
      )}
      {musicTypes.length > 3 && <div>+ {musicTypes.length - 3}</div>}
    </div>
  );
};

export default EventCategories;
