"use client";
import styles from "./EventInfo.module.css";
import { BsCalendar, BsBuilding } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import { AiOutlineRight } from "react-icons/ai";
import useFormatDates from "../../../hooks/useFormatDates";
import { useEffect, useState } from "react";

const EventInfo = ({
  startDate,
  endDate,
  timezoneMap,
  locationData,
  venueName,
  isLocationHidden,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (locationData?.geopoint) {
      setIsLoaded(true);
    }
  }, [locationData]);

  const { lat, lng } = locationData?.geopoint || {};
  const [eventStartDate, startRange, endRange] = useFormatDates(
    startDate,
    endDate,
    timezoneMap
  );

  return (
    <div className={styles.text_info}>
      {startRange && endRange && (
        <div className={styles.date_wrap}>
          <BsCalendar className={styles.logo} />
          <span className={styles.dateText}>From</span>
          <span className={styles.dateRange}>{startRange}</span>
          <span className={styles.dateText}>Till</span>{" "}
          <span className={styles.dateRange}>{endRange}</span>
        </div>
      )}
      {!isLocationHidden && venueName && (
        <div>
          <BsBuilding className={styles.logo} />
          <span>{venueName}</span>
        </div>
      )}
      {locationData?.city && locationData?.country && isLoaded && (
        <a
          href={
            true
              ? " "
              : `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className={styles.href}
        >
          <IoLocationSharp className={styles.logo} />
          <div className={styles.location_info}>
            {locationData.city}, {locationData.country}
            <AiOutlineRight className={styles.logo} />
          </div>
        </a>
      )}
    </div>
  );
};

export default EventInfo;
