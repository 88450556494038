import React from "react";
import styles from "./CustomLoading.module.css";

const CustomLoading = () => {
  return (
    <div className={styles.ldsRing}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default CustomLoading;
