import {
  differenceInCalendarDays,
  differenceInCalendarWeeks,
  differenceInCalendarMonths,
  startOfWeek,
  addDays,
  format,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const groupEvents = (events, period, mergeOngoingWithToday = true) => {
  const groups = {};
  const labels = {};

  const formatGroupKey = (key, isOngoing) => {
    if (isOngoing && !mergeOngoingWithToday) return "ONGOING EVENTS";
    if (key === "0" || (isOngoing && mergeOngoingWithToday)) return "TODAY";
    if (key === "1") return "TOMORROW";
    if (Number(key) < 0)
      return `PAST (${format(addDays(new Date(), Number(key)), "EEE dd MMM")})`;

    const date = addDays(new Date(), Number(key));
    return `${format(date, "EEE dd MMM")}`;
  };

  events.forEach((event) => {
    const timezone = event.timezoneMap.value;
    const now = utcToZonedTime(new Date(), timezone);
    const startDate = utcToZonedTime(
      new Date(event.startDate._seconds * 1000),
      timezone
    );
    const endDate = utcToZonedTime(
      new Date(event.endDate._seconds * 1000),
      timezone
    );
    let diff = "0";
    let isOngoing = false;

    if (now >= startDate && now <= endDate) {
      isOngoing = true;
    } else {
      switch (period) {
        case "day":
          diff = differenceInCalendarDays(startDate, now);
          break;
        case "week":
          const startWeekNow = startOfWeek(now, { weekStartsOn: 1 });
          diff = differenceInCalendarWeeks(startDate, startWeekNow);
          break;
        case "month":
          diff = differenceInCalendarMonths(startDate, now);
          break;
        default:
          throw new Error("Invalid period");
      }
    }

    if (isOngoing && mergeOngoingWithToday) {
      diff = "0";
    }

    if (groups[diff]) {
      groups[diff].push(event);
    } else {
      groups[diff] = [event];
    }

    labels[diff] = formatGroupKey(diff.toString(), isOngoing);
  });

  // Sort group keys in ascending order
  const sortedKeys = Object.keys(groups).sort((a, b) => a - b);

  // Create new sorted groups object
  const sortedGroups = {};
  const sortedLabels = {};
  sortedKeys.forEach((key) => {
    sortedGroups[key] = groups[key];
    sortedLabels[key] = labels[key];
  });

  return { groups: sortedGroups, labels: sortedLabels };
};
