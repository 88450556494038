import styles from "./MainEventSkeleton.module.css";

const MainEventSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrap_title}>
        <div className={styles.titleSkeleton}></div>
        <div className={styles.titleSkeleton_1}></div>
        <div className={styles.titleSkeleton_2}></div>
      </div>
      <div className={styles.eventImageSkeleton} />
      {/* <div className={styles.eventInfoSkeleton}> */}
      {/* <h2 className={styles.event_titleSkeleton}>{"name"}</h2> */}
      {/* <span>{eventStartDate}</span> */}
      {/* <span>
          {event.musicTypes && (
            <EventCategories musicTypes={event?.musicTypes} />
          )}
        </span> */}
      {/* <p className={styles.descSkeleton}>{"description"}</p> */}
      {/* </div> */}
    </div>
  );
};

export default MainEventSkeleton;
