"use client";
import EventCardSkeleton from "@components/UI/EventCardSkeleton/EventCardSkeleton";
import Navbar from "@layout/Navbar/Navbar";
import MainEventSkeleton from "@components/UI/MainEventSkeleton/MainEventSkeleton";
import styles from "./loading.module.css";
import { useEffect } from "react";
const Loading = () => {
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     window.location.reload();
  //   }, 2000); // 2000 milliseconds (2 seconds)

  //   return () => clearTimeout(timer); // Clear the timer if the component is unmounted
  // }, []);
  //test
  return (
    <div className={styles.wrapper}>
      <div className={styles.bg}></div>
      <Navbar />
      <div className={styles.column}>
        <MainEventSkeleton />
        <div className={styles.dateSkeleton}></div>
        <div className={styles.row}>
          <EventCardSkeleton />
          <EventCardSkeleton />
          <EventCardSkeleton />
        </div>
      </div>
    </div>
  );
};

export default Loading;
