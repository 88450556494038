import React, { useState, useEffect } from "react";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import styles from "./EventMap.module.css";
import { AiOutlineRight } from "react-icons/ai";
import { useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "var(--main-width-event-details)", // Using CSS variable
  height: "var(--main-height-event-details)", // Using CSS variable
  borderRadius: "20px",
};

class MapLibraries {
  static libraries = ["places"];
}

const EventMap = ({ location, isLocationHidden, eventID }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { isLoaded: isMapsApiLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
  });

  useEffect(() => {
    if (location && location.geopoint) {
      setIsLoaded(true);
    }
  }, [location]);

  let lat, lng;
  if (location && location.geopoint) {
    lat = location.geopoint.lat;
    lng = location.geopoint.lng;
  }

  if (!isMapsApiLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.wrapper}>
      <h2>LOCATION</h2>
      {!isLocationHidden && isLoaded && typeof window !== "undefined" ? (
        <>
          {lat !== undefined && lng !== undefined ? (
            <>
              <div>
                {eventID === "G3Uk0jfkuYScj3SFCG0V" ? (
                  <div className={styles.location_info}>
                    8-9 Argyll St, London W1F 7TF
                    <AiOutlineRight className={styles.logo} />
                  </div>
                ) : (
                  <div className={styles.location_info}>
                    {location.place}, {location.city}, {location.country}
                    <AiOutlineRight className={styles.logo} />
                  </div>
                )}
              </div>
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=18&size=600x400&maptype=roadmap&markers=color:red%7C${lat},${lng}&key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}`}
                  alt="Location Map"
                  className={styles.staticMap}
                />
              </a>
            </>
          ) : (
            <div className={styles.noLocationFound}>
              <img
                src="path_to_blurred_image.jpg"
                alt="No Location Found"
                className={styles.blurredImage}
              />
              <p>No location found: use client</p>
            </div>
          )}
        </>
      ) : (
        <h4>This event has a secret location that will be revealed soon</h4>
      )}
    </div>
  );
};

export default EventMap;
