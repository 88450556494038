"use client";
import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiOutlineClose,
} from "react-icons/ai";
import { useRouter } from "next/navigation";
import { Spinner } from "@components/Spinner/Spinner";
import styles from "./GetTheAppDialog.module.css";
import Image from "next/image";
import { isMobile } from "react-device-detect";

const GetTheAppDialog = ({
  title = "Get the Tick'it App",
  message = "",
  success = true,
  onVerified,
  eventID,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isClosing, setIsClosing] = useState(false);
  const router = useRouter();
  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
    }, 500);
  };

  return (
    <>
      {!isMobile ? (
        <></>
      ) : (
        <Dialog open={isOpen} onClose={closeModal} className={styles.overlay}>
          <Dialog.Overlay />
          <div className={`${styles.modal} ${isClosing ? styles.fadeOut : ""}`}>
            <Dialog.Title as="h3" className={styles.title}>
              <div className={styles.row}>
                <Image
                  src="/assets/images/logo.png"
                  alt="Tick'it Logo"
                  width={45}
                  height={45}
                  priority={true}
                />
                <span>{title}</span>
              </div>
              <span className={styles.btn} onClick={closeModal}>
                <AiOutlineClose className={styles.close} />
              </span>
            </Dialog.Title>
            <Dialog.Description as="div" className={styles.body}>
              <div>
                <p className={styles.message}>{message}</p>
                <div className={styles.btn_wrap}>
                  <a
                    href="https://app.adjust.com/1atilkb8?campaign=Website"
                    onClick={closeModal}
                  >
                    Download the app
                  </a>
                </div>
              </div>
            </Dialog.Description>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default GetTheAppDialog;
