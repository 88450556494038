import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Image from "next/image";
import styles from "./MainEvent.module.css";
import useFormatDates from "@hooks/useFormatDates";
import { useRouter } from "next/navigation";
import "./carousel.css";
import Markdown from "markdown-to-jsx";

const MainEvent = ({ mainEvents = [], mainOrgs = [] }) => {
  const router = useRouter();

  function formatMarkdown(markdown) {
    return markdown.replace(/\* /g, "\n* ");
  }

  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      // infiniteLoop={true}
      swipeable={true}
      dynamicHeight={true}
      showIndicators={true}
      className={styles.wrapper}
      selectedItem={0}
    >
      {mainEvents.map((event, index) => {
        const [eventStartDate, startRange, endRange] = useFormatDates(
          event?.startDate,
          event?.endDate,
          event?.timezoneMap
        );
        const isEvent = event.eventID;
        return (
          <div
            key={index} // Ensure a unique key based on event ID
            className={styles.carouselItem}
            onClick={() => {
              if (isEvent)
                router.push(`/events/${event.eventID ?? event.orgID}`);
              else
                router.push(`/organizations/${event.eventID ?? event.orgID}`);
            }}
          >
            <div className={styles.imageContainer}>
              <Image
                src={event.imageUrl}
                alt={event.name}
                className={styles.eventImage}
                width={400}
                height={200}
                priority={true}
                sizes="100vw"
              />
            </div>
            <div className={styles.wrapp}>
              <h3 className={styles.name}>{event.name}</h3>
              <div className={styles.event_info}>
                <span>{event?.orgSnippet?.orgName || "TEST"} </span>
                <span>|</span>
                <span className={styles.time}>{startRange || "TEST"}</span>
              </div>
              <Markdown className={styles.desc}>
                {formatMarkdown(event?.description ?? "").length > 400
                  ? `${formatMarkdown(event?.description ?? "").substring(
                      0,
                      400
                    )} ...`
                  : formatMarkdown(event?.description ?? "")}
              </Markdown>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default MainEvent;
