import styles from "./EventCardSkeleton.module.css";

const EventCardSkeleton = () => {
  return (
    <div className={styles.eventCardSkeleton}>
      <div className={styles.imageContainerSkeleton}>
        <div
          // src={event.imageUrl}
          // alt={event.name}
          className={styles.eventImageSkeleton}
        />
      </div>
      <h3 className={styles.nameSkeleton}></h3>
      <div className={styles.event_infoSkeleton}>
        <span>{"orgName"}</span>
        <span>|</span>
        <span>{"date"}</span>
      </div>
      {/* <EventCategories musicTypes={musicTypes} /> */}
    </div>
  );
};

export default EventCardSkeleton;
