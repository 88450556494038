import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import EventCard from "@components/EventCard/EventCard";
import { groupEvents } from "@helpers/groupEvents";
import styles from "./SearchBar.module.css";
import { AiOutlineClose } from "react-icons/ai";
import { getEvents } from "@services/eventAPI";

const SearchBar = ({ events = [], open, onClose }) => {
  const allEvents = events;
  const [searchTerm, setSearchTerm] = useState("");
  const [searchEvents, setSearchEvents] = useState([]);
  useEffect(() => {
    if (searchTerm.length >= 3) {
      const fetchEvents = async () => {
        try {
          const data = await getEvents("searchString", searchTerm);
          setSearchEvents(data);
        } catch (error) {
          console.error(error);
        }
      };
      fetchEvents();
    }
  }, [searchTerm]);

  const { groups, labels } = groupEvents(searchEvents, "day");

  let sortedKeys = Object.keys(groups).filter((key) => key !== "ongoing");
  sortedKeys = sortedKeys.sort((a, b) => Number(a) - Number(b));

  if (groups["ongoing"]) {
    sortedKeys.unshift("ongoing");
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className={styles.dialog} onClose={onClose}>
        <Dialog.Overlay className={styles.overlay} />
        <div onClick={onClose} className={styles.close}>
          <AiOutlineClose />
        </div>
        <div className={styles.searchBar}>
          <Dialog.Title>Search events</Dialog.Title>
          <input
            type="text"
            placeholder="Search events..."
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles.input}
          />
          {sortedKeys.map((key) => (
            <div key={key}>
              <h2 className={styles.date}>{labels[key]}</h2>
              <div className={styles.event}>
                {groups[key].map((event) => {
                  if (event.eventID !== "eHaECJSmHrBAs7ht3WoR")
                    return <EventCard event={event} key={event.eventID} />;
                })}
              </div>
            </div>
          ))}
        </div>
      </Dialog>
    </Transition>
  );
};

export default SearchBar;
