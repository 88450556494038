"use client";
import { useState, useEffect } from "react";

const useImageLoading = (imageUrl) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => setIsImageLoaded(true);
    }
  }, [imageUrl]);

  return isImageLoaded;
};

export default useImageLoading;
