"use client";
import Navbar from "@layout/Navbar/Navbar";
import React from "react";
import styles from "./ErrorCountry.module.css";
import { BsExclamationLg } from "react-icons/bs";
import Cookies from "js-cookie";
import { useRouter } from "next/navigation";
import { Spinner } from "@components/Spinner/Spinner";
import { useState, useEffect } from "react";

const ErrorCountry = ({
  errorMsg = "Contact support if you think this shouldn't be happening",
  allEvents = [],
}) => {
  const selectedCountry = Cookies.get("selectedCountry") || "";
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const [uniqueEventCountries, setUniqueEventCountries] = useState([]);
  useEffect(() => {
    if (allEvents.length > 0) {
      const eventCountries = allEvents.map((event) => event.eventCountry);
      const uniqueEventCountriesSet = Array.from(new Set(eventCountries));
      setUniqueEventCountries(uniqueEventCountriesSet);
    }
  }, [allEvents]);
  // // console.log(uniqueEventCountries);
  return (
    <>
      <Navbar />
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <div className={styles.circle}>
            <BsExclamationLg className={styles.icon} />
          </div>
        </div>
        <div className={styles.right}>
          <h3>Oops ! Something Went Wrong</h3>
          <span>{errorMsg}</span>
          <span>Here is a list of the country with available events :</span>
          {/* <button
            className={styles.btn}
            onClick={() => {
              window.location.reload();
            }}
          >
            {loading ? <Spinner /> : "Try again"}
          </button> */}
          {uniqueEventCountries.map((country) => {
            return (
              <button
                className={styles.btn}
                onClick={() => {
                  router.push(`/${country}`);
                  setLoading(true);
                }}
              >
                {loading ? <Spinner /> : `Go to ${country}`}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ErrorCountry;
