// EventImageSkeleton.jsx
import React from "react";
import styles from "./EventImageSkeleton.module.css";
import sharedStyles from "../SkeletonStyles.module.css";

const EventImageSkeleton = () => {
  return (
    <div className={styles.skeleton_img_wrapper}>
      <div
        className={`${styles.normal_img_skeleton} ${sharedStyles.skeleton}`}
      ></div>
    </div>
  );
};

export default EventImageSkeleton;
